import React, { Suspense } from "react";
import { Spin } from "antd";
const FileStorageContainer = React.lazy(
  () => import("../../../modules/FileStorage/components/index")
);

const FileStoragePage = () => (
  <Suspense fallback={<Spin />}>
    <FileStorageContainer />
  </Suspense>
);

export default FileStoragePage;
