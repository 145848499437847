import ReduxThunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import axiosMiddleware from "./middleware/axiosMiddleware";
import { rootReducer } from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

const isDevMode = process.env.NODE_ENV === "development";

const middleware = [ReduxThunk, axiosMiddleware];

if (isDevMode) {
  const { createLogger } = require("redux-logger");
  const loggerMiddleware = createLogger();
  middleware.push(loggerMiddleware);
}

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
