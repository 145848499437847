import prodConfig from "./prod";
import stageConfig from "./stage";

const CONFIG_ENV = process.env.REACT_APP_CONFIG_ENV || "";

const config = {
  sentry_dsn:
    "https://2b76cc300ea44e179bd6bcc1e62b4b72@o4505324534300672.ingest.sentry.io/4505510463602688",
};

const configByEnv = CONFIG_ENV === "stage" ? stageConfig : prodConfig;
const mergedConfigs = { ...config, ...configByEnv };

export default mergedConfigs;
