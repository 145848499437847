import React, { Suspense } from "react";
import { Spin } from "antd";
const PaymentsCategoriesContainer = React.lazy(
  () => import("../../../modules/PaymentsCategories/components")
);

const PaymentsCategoriesPage = () => (
  <Suspense fallback={<Spin />}>
    <PaymentsCategoriesContainer />
  </Suspense>
);

export default PaymentsCategoriesPage;
