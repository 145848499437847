import React from "react";
import { useLogin } from "../../../modules/Auth/api/apiHooks";
import AuthWrapper from "../../../modules/Auth/components/wrappers/AuthWrapper";
import LoginForm from "../../../modules/Auth/components/forms/LoginForm";

const Login = () => {
  const { mutate, isPending } = useLogin();

  const onSubmit = (values) =>
    mutate({
      email: values.email,
      password: values.password,
    });

  return (
    <AuthWrapper
      isLoading={isPending}
      pageContent="Hello, who's this?"
    >
      <LoginForm onSubmit={onSubmit} />
    </AuthWrapper>
  );
};

export default Login;
