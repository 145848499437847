import React from "react";
import { useGetBootstrapInfo } from "../../modules/Bootstrap/api/apiHooks";

type SystemTemplateProps = {
  children: React.ReactNode;
};

const SystemTemplate: React.FC<SystemTemplateProps> = ({ children }) => {
  useGetBootstrapInfo();
  return <>{children}</>;
};

export default SystemTemplate;
