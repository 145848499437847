import React, { Suspense } from "react";
import { Spin } from "antd";

const ServicesContainer = React.lazy(
  () => import("../../../modules/Services/components/index")
);

const ServicesPage = () => (
  <Suspense fallback={<Spin />}>
    <ServicesContainer />
  </Suspense>
);

export default ServicesPage;
