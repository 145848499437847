import React from "react";
import { Modal } from "antd";
import classNames from "classnames";
import { useModal, useHideModal } from "./api/hooks";
import { showModalType, modalType } from "./constants";

const ModalContainer: React.FC = () => {
  const { handleOnClose } = useHideModal();
  const modal: showModalType & modalType = useModal();

  const {
    show,
    width,
    centered = true,
    closable = false,
    maskClosable = true,
    customRender: CustomRender,
    wrapClassName,
    containerClass,
    title,
    desc,
    body,
    component: CustomComponent,
    componentProps,
    contentClass,
    footer = null,
    modalFooter,
    modalAfterFooter,
    onOk,
    okText,
    onCancel,
    cancelText,
  } = modal;
  const closeModal = () => {
    onCancel && onCancel();
    handleOnClose();
  };

  return (
    <Modal
      open={show}
      width={width}
      centered={centered}
      closable={closable}
      maskClosable={maskClosable}
      wrapClassName={classNames("modal", wrapClassName)}
      className={classNames("modal__content", containerClass)}
      footer={footer}
      destroyOnClose={true}
      onOk={onOk}
      okText={okText}
      onCancel={closeModal}
      cancelText={cancelText}
    >
      {CustomRender ? (
        <CustomRender {...componentProps} />
      ) : (
        <div
          className={classNames("modal-content", contentClass, {
            "modal-content--with-after-footer": modalAfterFooter,
          })}
        >
          <div className="modal-header">
            {title && <h2 className="modal-title">{title}</h2>}
            {desc && <p className="modal-desc">{desc}</p>}
          </div>
          <div className="modal-body">
            {CustomComponent ? <CustomComponent {...componentProps} /> : body}
          </div>
          {modalFooter && <div className="modal-footer">{modalFooter}</div>}
          {modalAfterFooter && (
            <div className="modal-after-footer">{modalAfterFooter}</div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ModalContainer;
