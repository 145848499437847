import { cleanObjFromEmptyVal } from "./helpfulFunctions";

export const isExternalURL = (url: string) => url.match(/http[s]?\/\//i);
export const isExternalModifiedURL = (url: string) =>
  url?.match(/http[s]?:\/\//i);
export const getQueryParams = (query: string | object) => {
  const cleanQuery = checkIfObj(query) ? cleanObjFromEmptyVal(query) : query;
  const params = new URLSearchParams(cleanQuery);
  return params;
};

export const getCorrectIconUrl = ({ genImgUrl, cdnUrl }, image) =>
  isExternalModifiedURL(genImgUrl(image))
    ? genImgUrl(image)
    : cdnUrl + genImgUrl(image);

const checkIfObj = (el) =>
  typeof el === "object" && !Array.isArray(el) && el !== null;
