import React, { Suspense } from "react";
import { Spin } from "antd";
const ProvidersContainer = React.lazy(
  () => import("../../../modules/Providers/components/index")
);

const ProvidersPage = () => (
  <Suspense fallback={<Spin />}>
    <ProvidersContainer />
  </Suspense>
);

export default ProvidersPage;
