import React from "react";
import { Link } from "react-router-dom";
import { RoleWrapper } from "../../../system/roles/RoleWrapper";
import { permissionType } from "../../../system/roles/constants";

type MenuItemProps = {
  route?: string;
  icon?: string;
  onClick?: any;
  label: string | React.ReactElement;
  access?: permissionType;
};

export const renderMenuItem = ({
  route,
  icon,
  onClick,
  label,
  access = null,
}: MenuItemProps) => (
  <RoleWrapper
    onClick={onClick}
    hasAccess={access}
    key={!!route ? route : Math.random()}
    children={
      <>
        {icon && <img alt="" src={icon} />}
        {!!route ? <Link to={route}>{label}</Link> : <span>{label}</span>}
      </>
    }
  />
);
