import React, { Suspense } from "react";
import { Spin } from "antd";
const TournamentsContainer = React.lazy(
  () => import("../../../modules/Tournaments/components/index")
);

const TournamentsPage = () => (
  <Suspense fallback={<Spin />}>
    <TournamentsContainer />
  </Suspense>
);

export default TournamentsPage;
