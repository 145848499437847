import { ThemeConfig } from "antd/es/config-provider/context";

export const themeConfig: ThemeConfig = {
  token: {
    controlHeight: 40,
  },
  components: {
    Select: {
      multipleItemHeight: 26,
    },
  },
};
