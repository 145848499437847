import React from "react";
import { Route, Routes } from "react-router-dom";
import { APP_ROUTES } from "./constants";
import { Action, Alias, Group, permissionType } from "../roles/constants";
import DashboardPage from "../../pages/dasboard";
import GamesPage from "../../pages/games/games";
import ProvidersPage from "../../pages/games/providers";
import CategoriesPage from "../../pages/games/categories";
import PrivateRoute from "./PrivateRoute";
import PageNotFound from "../../pages/pageNotFound";
import Login from "../../pages/auth/login";
import ResetPassword from "../../pages/auth/resetPassword";
import AccountListPage from "../../pages/accounts";
import AccountCreatePage from "../../pages/accounts/account/create";
import AccountEditPage from "../../pages/accounts/account/edit";
import RolesPage from "../../pages/roles";
import BrandsPage from "../../pages/brands";
import TranslationsPage from "../../pages/translations";
import CustomersPage from "../../pages/customers";
import PromotionsPage from "../../pages/promotions";
import BannersPage from "../../pages/content/banners";
import FaqsPage from "../../pages/content/faqs";
import PagesPage from "../../pages/content/pages";
import TermsPage from "../../pages/content/terms";
import NewsPage from "../../pages/content/news";
import ImagesPackPage from "../../pages/content/imagesPack";
import FileStoragePage from "../../pages/content/fileStorage";
import GatewaysPage from "../../pages/integrations/gateways";
import ServicesPage from "../../pages/integrations/services";
import ActionLogsPage from "../../pages/actionLogs";
import DepositPage from "../../pages/payments/deposit";
import WithdrawalPage from "../../pages/payments/withdrawal";
import BonusRequestPage from "../../pages/bonusRequest";
import TournamentsPage from "../../pages/content/tournaments";
import QuizPage from "../../pages/quiz";
import BonusDistributionPage from "../../pages/bonusDistribution";
import PaymentsCategoriesPage from "../../pages/payments/categories";
import CalendarsPage from "../../pages/calendars";
import CalendarDaysPage from "../../pages/calendars/calendarDays";
import CustomerInfoPage from "../../pages/customers/customerInfo";

const privateRoutes: {
  path: string;
  element: React.ReactNode;
  permissions: permissionType;
  brandRoute?: boolean;
}[] = [
  {
    path: APP_ROUTES.dashboard,
    element: <DashboardPage />,
    permissions: null,
  },
  {
    path: APP_ROUTES.actionLogs,
    element: <ActionLogsPage />,
    permissions: {
      group: [Group.ActivityLog],
      alias: [Alias.ActivityLog],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.translations,
    element: <TranslationsPage />,
    brandRoute: true,
    permissions: {
      group: [Group.Translate],
      alias: [Alias.Translate, Alias.PackTranslate],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.quiz,
    element: <QuizPage />,
    brandRoute: true,
    permissions: {
      group: [Group.User],
      alias: [Alias.User],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.bonusDistribution,
    element: <BonusDistributionPage />,
    brandRoute: true,
    permissions: {
      group: [Group.Bonus],
      alias: [Alias.Bonus],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.allGames,
    element: <GamesPage />,
    permissions: {
      group: [Group.Gamebox],
      alias: [Alias.GameboxSystemGame, Alias.GameboxBrandGame],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.showGame,
    element: <GamesPage />,
    permissions: {
      group: [Group.Gamebox],
      alias: [Alias.GameboxSystemGame, Alias.GameboxBrandGame],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.providers,
    element: <ProvidersPage />,
    permissions: {
      group: [Group.Gamebox],
      alias: [Alias.GameboxSystemProvider, Alias.GameboxBrandProvider],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.categories,
    element: <CategoriesPage />,
    permissions: {
      group: [Group.Gamebox],
      alias: [Alias.GameboxSystemCategory, Alias.GameboxBrandCategory],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.deposit,
    element: <DepositPage />,
    permissions: {
      group: [Group.Cashbox],
      alias: [
        Alias.SystemGateway,
        Alias.SystemMethodBrand,
        Alias.SystemMethod,
        Alias.BrandMethod,
      ],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.withdrawal,
    element: <WithdrawalPage />,
    permissions: {
      group: [Group.Cashbox],
      alias: [
        Alias.SystemGateway,
        Alias.SystemMethodBrand,
        Alias.SystemMethod,
        Alias.BrandMethod,
      ],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.paymentsCategories,
    element: <PaymentsCategoriesPage />,
    brandRoute: true,
    permissions: {
      group: [Group.Cashbox],
      alias: [
        Alias.SystemGateway,
        Alias.SystemMethodBrand,
        Alias.SystemMethod,
        Alias.BrandMethod,
      ],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.tournaments,
    element: <TournamentsPage />,
    brandRoute: true,
    permissions: {
      group: [Group.Content],
      alias: [Alias.Tournament],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.terms,
    element: <TermsPage />,
    brandRoute: true,
    permissions: {
      group: [Group.Terms],
      alias: [Alias.Terms],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.banners,
    brandRoute: true,
    element: <BannersPage />,
    permissions: {
      group: [Group.Content],
      alias: [Alias.Banner],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.pages,
    element: <PagesPage />,
    brandRoute: true,
    permissions: {
      group: [Group.Content],
      alias: [Alias.Page],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.news,
    element: <NewsPage />,
    brandRoute: true,
    permissions: {
      group: [Group.Content],
      alias: [Alias.News],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.faqs,
    element: <FaqsPage />,
    brandRoute: true,
    permissions: {
      group: [Group.Content],
      alias: [Alias.Faq],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.imagesPack,
    element: <ImagesPackPage />,
    brandRoute: true,
    permissions: {
      group: [Group.Content],
      alias: [Alias.Image],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.promotions,
    element: <PromotionsPage />,
    brandRoute: true,
    permissions: {
      group: [Group.Content],
      alias: [Alias.Promotion],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.calendars,
    element: <CalendarsPage />,
    brandRoute: true,
    permissions: {
      group: [Group.Calendar],
      alias: [Alias.Calendar],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.showCalendar,
    element: <CalendarDaysPage />,
    brandRoute: true,
    permissions: {
      group: [Group.Calendar],
      alias: [Alias.CalendarDay],
      action: [Action.index, Action.show],
    },
  },
  {
    path: APP_ROUTES.fileStorage,
    element: <FileStoragePage />,
    brandRoute: true,
    permissions: {
      group: [Group.Storage],
      alias: [Alias.SystemFile, Alias.BrandFile],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.accounts,
    element: <AccountListPage />,
    permissions: {
      group: [Group.Admin],
      alias: [Alias.Admin],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.accountCreate,
    element: <AccountCreatePage />,
    permissions: {
      group: [Group.Admin],
      alias: [Alias.Admin],
      action: [Action.store],
    },
  },
  {
    path: APP_ROUTES.accountEdit,
    element: <AccountEditPage />,
    permissions: {
      group: [Group.Admin],
      alias: [Alias.Admin],
      action: [Action.update],
    },
  },
  {
    path: APP_ROUTES.brands,
    element: <BrandsPage />,
    permissions: {
      group: [Group.Brand, Group.Seo],
      alias: [Alias.Brand, Alias.Seo],
      action: [Action.index, Action.show],
    },
  },
  {
    path: APP_ROUTES.roles,
    element: <RolesPage />,
    permissions: {
      group: [Group.Authorization],
      alias: [Alias.Role],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.customers,
    element: <CustomersPage />,
    brandRoute: true,
    permissions: {
      group: [Group.User],
      alias: [Alias.User],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.customerInfo,
    element: <CustomerInfoPage />,
    permissions: {
      group: [Group.User],
      alias: [Alias.User],
      action: [Action.show],
    },
  },
  {
    path: APP_ROUTES.bonusRequest,
    element: <BonusRequestPage />,
    brandRoute: true,
    permissions: {
      group: [Group.User],
      alias: [Alias.User],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.gateways,
    element: <GatewaysPage />,
    brandRoute: true,
    permissions: {
      group: [Group.Integrations],
      alias: [Alias.Integration],
      action: [Action.index],
    },
  },
  {
    path: APP_ROUTES.services,
    element: <ServicesPage />,
    brandRoute: true,
    permissions: {
      group: [Group.Integrations],
      alias: [Alias.Integration],
      action: [Action.index],
    },
  },
];

export default (
  <Routes>
    <Route path={APP_ROUTES.login} element={<Login />} />
    <Route path={APP_ROUTES.resetPassword} element={<ResetPassword />} />
    {privateRoutes.map(({ path, element, permissions, brandRoute }, key) => (
      <Route
        key={key}
        path={path}
        element={
          <PrivateRoute permissions={permissions} brandRoute={brandRoute}>
            {element}
          </PrivateRoute>
        }
      />
    ))}
    <Route element={<PageNotFound />} />
  </Routes>
);
