import React, { Suspense } from "react";
import { Spin } from "antd";
const CustomersContainer = React.lazy(
  () => import("../../modules/Customers/components/index")
);

const CustomersPage = () => (
  <Suspense fallback={<Spin />}>
    <CustomersContainer />
  </Suspense>
);

export default CustomersPage;
