import React, { Suspense } from "react";
import { Spin } from "antd";
const BonusContainer = React.lazy(
  () => import("../../modules/BonusDistribution/components/index")
);

const BonusDistributionPage = () => (
  <Suspense fallback={<Spin />}>
    <BonusContainer />
  </Suspense>
);

export default BonusDistributionPage;
