import { permissionType, Role } from "../roles/constants";
import { notEmptyArr } from "../helpers/helpfulFunctions";

export const usePermissionAccess = () => {
  const authData = JSON.parse(localStorage.getItem("authData"));

  const isSuperAdmin = Role.SuperAdmin === authData?.name;

  const hasGlobalConfigAccess = !!authData?.permissions.filter((perm) =>
    perm.alias.toLowerCase().includes("system")
  ).length;

  const checkPermission = (permissions: permissionType) => {
    const hasPermission = authData?.permissions.filter(
      (adminPerm) =>
        permissions &&
        Object.keys(permissions).every((namespace) => {
          return permissions[namespace].indexOf(adminPerm[namespace]) !== -1;
        })
    );
    return permissions === null ? true : notEmptyArr(hasPermission);
  };

  return {
    isSuperAdmin,
    checkPermission,
    hasGlobalConfigAccess,
  };
};
