import axios from "axios";
import backendUrl from "./apiUrl";
import { isLoggedIn } from "../helpers/helpfulFunctions";
import { getCookie } from "../helpers/cookies";

const axiosClient = () =>
  axios.create({
    baseURL: backendUrl,
    responseType: "json",
    headers: {
      "X-Admin-Token": isLoggedIn() ? getCookie("token") : "",
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

export default axiosClient;
