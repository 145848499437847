export const popFromUrl = (path) => path.split("/").slice(-1).pop();
export const isNumeric = (num) => !isNaN(num);

export const arrayRemove = (arr, value) => arr.filter((el) => el !== value);

export const arrayRemoveByValues = (arr, values) =>
  arr.filter((el) => !values?.includes(el));

const disableTitleItems = [
  "dashboard",
  "brands",
  "all games",
  "providers",
  "categories",
  "promotions",
  "banners",
  "faqs",
  "pages",
  "news",
  "tournaments",
  "terms",
  "images-pack",
  "file-storage",
  "gateways",
  "services",
  "deposit",
  "withdrawal",
  "quiz",
  "bonus distribution",
  "calendars",
]; // add title name here if you want to remove title with back navigation btn
export const disableTitle = (title) => {
  return !!title && !disableTitleItems.includes(title.toLowerCase());
};
