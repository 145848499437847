import { useCallback } from "react";
import { shallowEqual } from "react-redux";
import { getCurrentModal } from "./selectors";
import {
  hideModalAction,
  showModalAction,
  updateModalDataAction,
} from "./actions";
import { modalType } from "../constants";
import {
  useTypedDispatch,
  useTypedSelector,
} from "../../../../system/hooks/useAppDispatch";

export const useModal = () => useTypedSelector(getCurrentModal, shallowEqual);

export const useShowModal = (
  props: modalType = {},
  callback: () => void = null
): any => {
  const dispatch = useTypedDispatch();
  const currentModal = useModal();

  return useCallback(
    (additionalProps, isUpdate = false) => {
      const propsResult = { ...props };

      if (additionalProps) {
        propsResult.componentProps = {
          ...(isUpdate
            ? currentModal?.componentProps
            : propsResult.componentProps),
          ...additionalProps,
        };

        if (isUpdate) {
          dispatch(updateModalDataAction(propsResult));
          return;
        }
      }

      dispatch(showModalAction(propsResult));
      callback && callback();
    },
    [dispatch, props, callback, currentModal]
  );
};

export const useHideModal = () => {
  const dispatch = useTypedDispatch();
  const handleOnClose = useCallback(() => {
    dispatch(hideModalAction());
  }, [dispatch]);

  return {
    handleOnClose,
  };
};
