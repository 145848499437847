import React, { Suspense } from "react";
import { Spin } from "antd";
const BannersContainer = React.lazy(
  () => import("../../../modules/Banners/components/index")
);

const BannersPage = () => (
  <Suspense fallback={<Spin />}>
    <BannersContainer />
  </Suspense>
);

export default BannersPage;
