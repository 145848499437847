import { SHOW_MODAL, HIDE_MODAL, UPDATE_MODAL_DATA } from "../constants";

const initialState = {
  show: false,
  componentProps: {}
};

const modal = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SHOW_MODAL:
      return {
        show: true,
        ...payload
      };
    case HIDE_MODAL:
      return {
        ...state,
        show: false
      };
    case UPDATE_MODAL_DATA:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};

export default modal;
