import { SHOW_MODAL, HIDE_MODAL, UPDATE_MODAL_DATA } from "../constants";

export const showModalAction = (props) => (dispatch: any): void => {
  return dispatch({
    type: SHOW_MODAL,
    payload: props
  });
};

export const hideModalAction = () => (dispatch: any): void => {
  return dispatch({
    type: HIDE_MODAL
  });
};

export const updateModalDataAction = (props) => (dispatch: any): void => {
  return dispatch({
    type: UPDATE_MODAL_DATA,
    payload: props
  });
};
