import React from "react";
import { Navigate } from "react-router-dom";
import { useConfig } from "../hooks/useConfig";
import { APP_ROUTES } from "./constants";
import { cachedBrand } from "../../modules/ActiveBrand/constants";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../reducers";
import { isLoggedIn } from "../helpers/helpfulFunctions";
import { RoleWrapper } from "../roles/RoleWrapper";

const PrivateRoute = ({
  children,
  permissions = {
    group: [],
    alias: [],
    action: [],
  },
  brandRoute = false,
}) => {
  const { isGlobalConfig } = useConfig();
  const { token } = useSelector(
    (state: ApplicationState) => state.auth,
    shallowEqual
  );

  const unauthorized = !token && !isLoggedIn();

  if (!cachedBrand && isGlobalConfig && brandRoute) {
    return <Navigate to={APP_ROUTES.dashboard} />;
  }

  if (unauthorized) {
    return <Navigate to={APP_ROUTES.login} />;
  }

  return (
    <RoleWrapper hasAccess={permissions} redirect>
      {children}
    </RoleWrapper>
  );
};

export default PrivateRoute;
