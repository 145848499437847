import React, { Children, cloneElement } from "react";
import { Navigate } from "react-router-dom";
import { usePermissionAccess } from "../hooks/usePermissionAccess";
import { APP_ROUTES } from "../router/constants";
import { permissionType } from "./constants";

interface RoleWrapperProps {
  children: any;
  hasAccess: permissionType;
  redirect?: boolean;
  onClick?: any;
  defaultComponent?: any;
}

export const RoleWrapper: React.FC<RoleWrapperProps> = ({
  hasAccess,
  redirect,
  defaultComponent,
  children,
  onClick,
  ...props
}) => {
  const { checkPermission, isSuperAdmin } = usePermissionAccess();
  const accessControl = checkPermission(hasAccess);

  const childrenWithProps = Children.map(children, (child) =>
    cloneElement(child, { ...props, onClick })
  );

  if (!accessControl && !isSuperAdmin && redirect) {
    return <Navigate to={APP_ROUTES.dashboard} />;
  } else if (!accessControl && !isSuperAdmin && !redirect) {
    return defaultComponent;
  }

  return childrenWithProps;
};

RoleWrapper.defaultProps = {
  defaultComponent: null,
  redirect: false,
  onClick: null,
};
