import { SiderTheme } from "antd/lib/layout/Sider";
import { APP_ROUTES } from "../../../system/router/constants";
import { ReactComponent as DashboardIcon } from "../../../images/icons/sidebar-icons/dashboard.svg";
import { ReactComponent as DashboardIconActive } from "../../../images/icons/sidebar-icons/dashboard-active.svg";
import { ReactComponent as TranslationIcon } from "../../../images/icons/sidebar-icons/translation.svg";
import { ReactComponent as TranslationIconActive } from "../../../images/icons/sidebar-icons/translation-active.svg";
import { ReactComponent as GamesIcon } from "../../../images/icons/sidebar-icons/games.svg";
import { ReactComponent as GamesIconActive } from "../../../images/icons/sidebar-icons/games-active.svg";
import { ReactComponent as PaymentIcon } from "../../../images/icons/sidebar-icons/payment.svg";
import { ReactComponent as PaymentIconActive } from "../../../images/icons/sidebar-icons/payment-active.svg";
import { ReactComponent as CustomersIcon } from "../../../images/icons/sidebar-icons/customers.svg";
import { ReactComponent as CustomersIconActive } from "../../../images/icons/sidebar-icons/customers-active.svg";
import { ReactComponent as ContentIcon } from "../../../images/icons/sidebar-icons/content.svg";
import { ReactComponent as ContentIconActive } from "../../../images/icons/sidebar-icons/content-active.svg";
import { ReactComponent as PromotionsIcon } from "../../../images/icons/sidebar-icons/promotions.svg";
import { ReactComponent as PromotionsActiveIcon } from "../../../images/icons/sidebar-icons/promotions-active.svg";
import { ReactComponent as IntegrationsIcon } from "../../../images/icons/sidebar-icons/integrations.svg";
import { ReactComponent as IntegrationsIconActive } from "../../../images/icons/sidebar-icons/integrations-active.svg";
import { ReactComponent as QuizIcon } from "../../../images/icons/sidebar-icons/quiz.svg";
import { ReactComponent as QuizIconActive } from "../../../images/icons/sidebar-icons/quiz-active.svg";
import { ReactComponent as BonusIcon } from "../../../images/icons/sidebar-icons/bonuse.svg";
import { ReactComponent as BonusIconActive } from "../../../images/icons/sidebar-icons/bonuse-active.svg";
import { Action, Alias, Group } from "../../../system/roles/constants";

export const GLOBAL_CONFIG = "Global Configuration";

interface sidebarConfProps {
  width?: number | string;
  collapsedWidth?: number | string;
  breakpoint?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
  theme?: SiderTheme;
}

export const sidebarConfig: sidebarConfProps = {
  theme: "light",
  breakpoint: "lg",
  collapsedWidth: "0",
  width: "256",
};

export const sidebarData = (hideOnGlobConf = false) => [
  {
    label: "Dashboard",
    route: APP_ROUTES.dashboard,
    icon: <DashboardIcon />,
    activeIcon: <DashboardIconActive />,
    subData: null,
    access: null,
  },
  {
    label: "Translations",
    route: APP_ROUTES.translations,
    icon: <TranslationIcon />,
    activeIcon: <TranslationIconActive />,
    subData: null,
    hidden: hideOnGlobConf,
    access: {
      group: [Group.Translate],
      alias: [Alias.Translate, Alias.PackTranslate],
      action: [Action.index],
    },
  },
  {
    label: "Games",
    icon: <GamesIcon />,
    activeIcon: <GamesIconActive />,
    subData: [
      {
        label: "All Games",
        route: APP_ROUTES.allGames,
        access: {
          group: [Group.Gamebox],
          alias: [Alias.GameboxSystemGame, Alias.GameboxBrandGame],
          action: [Action.index],
        },
      },
      {
        label: "Providers",
        route: APP_ROUTES.providers,
        access: {
          group: [Group.Gamebox],
          alias: [Alias.GameboxSystemProvider, Alias.GameboxBrandProvider],
          action: [Action.index],
        },
      },
      {
        label: "Categories",
        route: APP_ROUTES.categories,
        access: {
          group: [Group.Gamebox],
          alias: [Alias.GameboxSystemCategory, Alias.GameboxBrandCategory],
          action: [Action.index],
        },
      },
    ],
    access: {
      group: [Group.Gamebox],
      alias: [
        Alias.GameboxSystemGame,
        Alias.GameboxBrandGame,
        Alias.GameboxSystemProvider,
        Alias.GameboxBrandProvider,
        Alias.GameboxSystemCategory,
        Alias.GameboxBrandCategory,
      ],
      action: [Action.index],
    },
  },
  {
    label: "Payments",
    icon: <PaymentIcon />,
    activeIcon: <PaymentIconActive />,
    subData: [
      {
        label: "Deposit",
        route: APP_ROUTES.deposit,
        access: {
          group: [Group.Cashbox],
          alias: [
            Alias.SystemGateway,
            Alias.SystemMethodBrand,
            Alias.SystemMethod,
            Alias.BrandMethod,
          ],
          action: [Action.index],
        },
      },
      {
        label: "Withdrawal",
        route: APP_ROUTES.withdrawal,
        access: {
          group: [Group.Cashbox],
          alias: [
            Alias.SystemGateway,
            Alias.SystemMethodBrand,
            Alias.SystemMethod,
            Alias.BrandMethod,
          ],
          action: [Action.index],
        },
      },
      {
        label: "Categories",
        route: APP_ROUTES.paymentsCategories,
        hidden: hideOnGlobConf,
        access: {
          group: [Group.Cashbox],
          alias: [
            Alias.SystemGateway,
            Alias.SystemMethodBrand,
            Alias.SystemMethod,
            Alias.BrandMethod,
          ],
          action: [Action.index],
        },
      },
    ],
    access: {
      group: [Group.Cashbox],
      alias: [
        Alias.SystemGateway,
        Alias.SystemMethodBrand,
        Alias.SystemMethod,
        Alias.BrandMethod,
      ],
      action: [Action.index],
    },
  },
  {
    label: "Content",
    icon: <ContentIcon />,
    activeIcon: <ContentIconActive />,
    hidden: hideOnGlobConf,
    subData: [
      {
        label: "Banners",
        route: APP_ROUTES.banners,
        access: {
          group: [Group.Content],
          alias: [Alias.Banner],
          action: [Action.index],
        },
      },
      {
        label: "FAQ",
        route: APP_ROUTES.faqs,
        access: {
          group: [Group.Content],
          alias: [Alias.Faq],
          action: [Action.index],
        },
      },
      {
        label: "Pages",
        route: APP_ROUTES.pages,
        access: {
          group: [Group.Content],
          alias: [Alias.Page],
          action: [Action.index],
        },
      },
      {
        label: "File storage",
        route: APP_ROUTES.fileStorage,
        access: {
          group: [Group.Storage],
          alias: [Alias.BrandFile, Alias.SystemFile],
          action: [Action.index],
        },
      },
      {
        label: "Images Pack",
        route: APP_ROUTES.imagesPack,
        access: {
          group: [Group.Content],
          alias: [Alias.Image],
          action: [Action.index],
        },
      },
      {
        label: "News",
        route: APP_ROUTES.news,
        access: {
          group: [Group.Content],
          alias: [Alias.News],
          action: [Action.index],
        },
      },
      {
        label: "Terms and condition",
        route: APP_ROUTES.terms,
        access: {
          group: [Group.Terms],
          alias: [Alias.Terms],
          action: [Action.index],
        },
      },
    ],
    access: {
      group: [Group.Content],
      alias: [
        Alias.Promotion,
        Alias.Banner,
        Alias.Page,
        Alias.SystemFile,
        Alias.BrandFile,
        Alias.Faq,
        Alias.Image,
        Alias.News,
        Alias.Terms,
      ],
      action: [Action.index],
    },
  },
  {
    label: "Promotions",
    icon: <PromotionsIcon />,
    activeIcon: <PromotionsActiveIcon />,
    hidden: hideOnGlobConf,
    subData: [
      {
        label: "Promotions",
        route: APP_ROUTES.promotions,
        access: {
          group: [Group.Content],
          alias: [Alias.Promotion],
          action: [Action.index],
        },
      },
      {
        label: "Tournaments",
        route: APP_ROUTES.tournaments,
        access: {
          group: [Group.Content],
          alias: [Alias.Tournament],
          action: [Action.index],
        },
      },
      {
        label: "Calendars",
        route: APP_ROUTES.calendars,
        access: {
          group: [Group.Calendar],
          alias: [Alias.Calendar],
          action: [Action.index],
        },
      },
    ],
    access: {
      group: [Group.Content, Group.Calendar],
      alias: [
        Alias.Promotion,
        Alias.Calendar,
        Alias.CalendarDay,
        Alias.Tournament,
      ],
      action: [Action.index],
    },
  },
  {
    label: "Integrations",
    icon: <IntegrationsIcon />,
    activeIcon: <IntegrationsIconActive />,
    hidden: hideOnGlobConf,
    subData: [
      {
        label: "Gateways",
        route: APP_ROUTES.gateways,
        access: {
          group: [Group.Integrations],
          alias: [Alias.Integration],
          action: [Action.index],
        },
      },
      {
        label: "Services",
        route: APP_ROUTES.services,
        access: {
          group: [Group.Integrations],
          alias: [Alias.Integration],
          action: [Action.index],
        },
      },
    ],
    access: {
      group: [Group.Integrations],
      alias: [Alias.Integration],
      action: [Action.index],
    },
  },
  {
    label: "Customers",
    icon: <CustomersIcon />,
    activeIcon: <CustomersIconActive />,
    hidden: hideOnGlobConf,
    subData: [
      {
        label: "Customers",
        route: APP_ROUTES.customers,
        access: {
          group: [Group.User],
          alias: [Alias.User],
          action: [Action.index],
        },
      },
      {
        label: "Bonus request",
        route: APP_ROUTES.bonusRequest,
        access: {
          group: [Group.User],
          alias: [Alias.UserClaim],
          action: [Action.index],
        },
      },
    ],
    access: {
      group: [Group.User],
      alias: [Alias.User],
      action: [Action.index],
    },
  },
  {
    label: "Quiz",
    icon: <QuizIcon />,
    activeIcon: <QuizIconActive />,
    route: APP_ROUTES.quiz,
    subData: null,
    hidden: hideOnGlobConf,
    access: {
      group: [Group.Quiz],
      alias: [Alias.Quiz],
      action: [Action.index],
    },
  },
  {
    label: "Bonus distribution",
    icon: <BonusIcon />,
    activeIcon: <BonusIconActive />,
    route: APP_ROUTES.bonusDistribution,
    subData: null,
    hidden: hideOnGlobConf,
    access: {
      group: [Group.Bonus],
      alias: [Alias.Bonus],
      action: [Action.index],
    },
  },
];
