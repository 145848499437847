import React, { Suspense } from "react";
import { Spin } from "antd";
const FaqsContainer = React.lazy(
  () => import("../../../modules/Faqs/components/index")
);

const FaqsPage = () => (
  <Suspense fallback={<Spin />}>
    <FaqsContainer />
  </Suspense>
);

export default FaqsPage;
