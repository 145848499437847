import React, { Suspense } from "react";
import { Spin } from "antd";
const ActionLogsContainer = React.lazy(
  () => import("../../modules/ActionLogs/components/index")
);

const ActionLogsPage = () => (
  <Suspense fallback={<Spin />}>
    <ActionLogsContainer />
  </Suspense>
);

export default ActionLogsPage;