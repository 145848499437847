import React, { Suspense } from "react";
import { Spin } from "antd";
const PagesContainer = React.lazy(
  () => import("../../../modules/Pages/components/index")
);

const PagesPage = () => (
  <Suspense fallback={<Spin />}>
    <PagesContainer />
  </Suspense>
);

export default PagesPage;
