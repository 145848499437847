import { combineReducers } from "redux";
import auth from "../../modules/Auth/api/reducer";
import bootstrap from "../../modules/Bootstrap/api/reducer";
import activeBrand from "../../modules/ActiveBrand/api/reducer";
import modal from "../../components/organisms/Modal/api/reducer";
import { authDataType } from "../../modules/Auth/constants";
import { activeBrandDataType } from "../../modules/ActiveBrand/constants";
import { bootstrapDataType } from "../../modules/Bootstrap/constants";
import { showModalType } from "../../components/organisms/Modal/constants";

export interface ApplicationState {
  auth: authDataType;
  bootstrap: bootstrapDataType;
  activeBrand: activeBrandDataType;
  modal: showModalType;
}

export const rootReducer = combineReducers<ApplicationState>({
  auth: auth,
  bootstrap: bootstrap,
  activeBrand: activeBrand,
  modal: modal,
});
