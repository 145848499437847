export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const UPDATE_MODAL_DATA = "UPDATE_MODAL_DATA";

export type showModalType = {
  show: boolean;
  componentProps: any;
};

export type modalType = {
  width?: string | number;
  centered?: boolean;
  closable?: boolean;
  maskClosable?: boolean;
  customRender?: any;
  wrapClassName?: string;
  containerClass?: string;
  title?: string;
  desc?: string;
  body?: any;
  component?: any;
  componentProps?: any;
  contentClass?: string;
  footer?: any;
  modalFooter?: any;
  modalAfterFooter?: any;
  onOk?: (e) => any;
  okText?: string;
  onCancel?: () => any;
  cancelText?: string;
};
