import * as Yup from "yup";
import { getUniqueElements } from "./helpfulFunctions";

export const IMAGE_FILE_SIZE = 5000 * 1024;
export const IMAGE_SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/webm",
];

export const isValidEmail = () =>
  Yup.string()
    .email("Invalid email value")
    .matches(/^[A-z+0-9+@+.]+$/, "Invalid email value");

export const isValidDomain = () =>
  Yup.string().matches(
    /^[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,6}$/i,
    "Invalid domain, example domainname.com, stage1.domain-name.com"
  );

export const isValidSlug = () =>
  Yup.string().matches(
    /^[a-z0-9]+(?:[-_][a-z0-9]+)*$/,
    "Only lowercase Latin letters,(0-9),(-_)"
  );

export const isNotEmptyObj = (msg = "") =>
  Yup.object().test(
    "empty-check",
    msg || "This field can't be empty",
    (field) => {
      const objVal: string = field && Object.values(field)[0];
      return !!objVal?.length;
    }
  );

export const isValidPassword = () =>
  Yup.string()
    .min(8, "Password must be longer")
    .max(30, "Password must be shorter")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/,
      "Password must include at least one number, lowercase and uppercase characters"
    );

export const isValidImageFile = () =>
  Yup.mixed()
    .test("fileFormat", "Unsupported format", (value) =>
      value && value[0] ? IMAGE_SUPPORTED_FORMATS.includes(value[0].type) : true
    )
    .test("fileSize", "File size too large", (value) =>
      value && value[0] ? value[0].size <= IMAGE_FILE_SIZE : true
    );

export const isValidPaymentInput = () =>
  Yup.mixed().test("type", "Inputs must be valid JSON", (value: any) => {
    try {
      JSON.parse(value);
    } catch (e) {
      return !value;
    }
    return typeof JSON.parse(value) === "object";
  });

export const isValidGameProviderTextArea = () =>
  Yup.mixed().test("type", "Inputs must be valid JSON", (value: any) => {
    try {
      JSON.parse(value);
    } catch (e) {
      return !value;
    }
    return typeof JSON.parse(value) === "object";
  });

export const isValidDisplayCondition = ({ countries, languages }) =>
  Yup.object().shape({
    user_state: Yup.string().nullable(),
    country: Yup.array().test("invalid-country", "Invalid country", (value) => {
      const isValid = !getUniqueElements(value, countries).length;
      return isValid;
    }),
    locale: Yup.array().test("invalid-locale", "Invalid locale", (value) => {
      const isValid = !getUniqueElements(value, languages).length;
      return isValid;
    }),
    device: Yup.string().nullable(),
  });
