import React from "react";
import { Layout } from "antd";
import { Content, Footer } from "antd/lib/layout/layout";

const currentYear = new Date().getFullYear();

export const NotLoggedUser = ({ children }) => (
  <Layout className="layout unauthorized">
    <Content className="login-wrapper">
      <div className="site-layout-background">{children}</div>
    </Content>
    <Footer>Copyright ©{currentYear}. All rights reserved.</Footer>
  </Layout>
);
