import React from "react";
import { APP_ROUTES } from "../system/router/constants";
import { Action, Alias, Group } from "../system/roles/constants";
import { ReactComponent as UserLogo } from "../images/icons/header-dropdown/user-logo.svg";
import { renderMenuItem } from "../components/atoms/MenuItem";

const routesAccess = {
  accounts: {
    group: [Group.Admin],
    alias: [Alias.Admin],
    action: [Action.index],
  },
  actionLogs: {
    group: [Group.ActivityLog],
    alias: [Alias.ActivityLog],
    action: [Action.index],
  },
  brandSettings: {
    group: [Group.Brand, Group.Seo],
    alias: [Alias.Brand, Alias.Seo],
    action: [Action.index, Action.show],
  },
};

export const headerDropdownData = ({
  username,
  logout,
  checkPermission,
  isSuperAdmin,
}) => {
  const items = [
    {
      key: "username",
      label: { label: username },
      icon: <UserLogo />,
    },
    {
      key: "accounts",
      label: {
        route: APP_ROUTES.accounts,
        label: "Accounts",
        access: routesAccess.accounts,
      },
    },
    {
      key: "action-logs",
      label: {
        route: APP_ROUTES.actionLogs,
        label: "Action Logs",
        access: routesAccess.actionLogs,
      },
    },
    {
      key: "brand-settings",
      label: {
        route: APP_ROUTES.brands,
        label: "Brand settings",
        access: routesAccess.brandSettings,
      },
    },
    {
      key: "logout",
      label: {
        route: APP_ROUTES.login,
        label: "Logout",
      },
      onClick: logout,
    },
  ];

  return items.map((item) => {
    if (!item.label.access) return { ...item, ...item.label };
    return isSuperAdmin || checkPermission(item.label.access)
      ? { ...item, label: renderMenuItem(item.label) }
      : null;
  });
};
