import React, { Suspense } from "react";
import { Spin } from "antd";
const BrandContainer = React.lazy(
  () => import("../../modules/Brands/components/index")
);

const BrandsPage = () => (
  <Suspense fallback={<Spin />}>
    <BrandContainer />
  </Suspense>
);

export default BrandsPage;
