import React, { Suspense } from "react";
import { Spin } from "antd";
const WithdrawalContainer = React.lazy(
  () => import("../../../modules/Withdrawal/components")
);

const DepositPage = () => (
  <Suspense fallback={<Spin />}>
    <WithdrawalContainer />
  </Suspense>
);

export default DepositPage;
