import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as Sentry from "@sentry/react";
import config from "./system/config";
const projectEnv: string = process.env.NODE_ENV || "";
const platformEnv: string = process.env.REACT_APP_PLATFORM_ENV || "";
/*import reportWebVitals from './reportWebVitals';*/

if (projectEnv !== "development") {
  Sentry.init({
    dsn: config.sentry_dsn,
    environment: platformEnv,
  });
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/*reportWebVitals();*/
