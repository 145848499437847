import React, { Suspense } from "react";
import { Spin } from "antd";
const CustomerInfoTemplate = React.lazy(
  () => import("../../../modules/Customers/components/CustomerInfoTemplate")
);

const CustomerInfoPage = () => (
  <Suspense fallback={<Spin />}>
    <CustomerInfoTemplate />
  </Suspense>
);

export default CustomerInfoPage;
