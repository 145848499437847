import React, { Suspense } from "react";
import { Spin } from "antd";
const GamesContainer = React.lazy(
  () => import("../../../modules/Games/components/index")
);

const GamesPage = () => (
  <Suspense fallback={<Spin />}>
    <GamesContainer />
  </Suspense>
);

export default GamesPage;
