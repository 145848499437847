import React, { Suspense } from "react";
import { Spin } from "antd";
const TranslationContainer = React.lazy(
  () => import("../../modules/Translations/components/index")
);

const TranslationsPage = () => (
  <Suspense fallback={<Spin />}>
    <TranslationContainer />
  </Suspense>
);

export default TranslationsPage;
