import React from "react";
import { Button } from "antd";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { isExternalURL } from "../../../system/helpers/urlHelper";

interface AntBtnProps {
  className?: string;
  type?: "primary" | "dashed" | "link" | "text" | "default";
  htmlType?: "button" | "submit" | "reset";
  disabled?: boolean;
  loading?: boolean;
  icon?: React.ReactElement;
  size?: "large" | "middle" | "small";
  block?: boolean;
  linkTo?: string;
  onClick?: (e) => any;
  text?: string | React.ReactElement;
}

const AntBtn: React.FC<AntBtnProps> = ({
  className,
  type,
  htmlType,
  disabled,
  loading,
  icon,
  size,
  block,
  linkTo,
  onClick,
  text,
}) => {
  let navigate = useNavigate();
  const handleClick = (e) => {
    if (linkTo) {
      if (isExternalURL(linkTo)) {
        window.location.assign(linkTo);
      } else {
        navigate(linkTo);
      }
    }
    onClick && onClick(e);
  };

  return (
    <Button
      className={classNames("btn", `btn-${type}`, className)}
      type={type}
      htmlType={htmlType}
      disabled={disabled}
      loading={loading}
      icon={icon}
      size={size}
      block={block}
      onClick={(e) => handleClick(e)}
    >
      {text}
    </Button>
  );
};

AntBtn.defaultProps = {
  type: "default",
};

export default AntBtn;
