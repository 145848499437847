import React, { Suspense } from "react";
import { Spin } from "antd";
import { useParams } from "react-router-dom";
const CalendarDaysContainer = React.lazy(
  () => import("../../../modules/Calendars/components/calendarDays")
);

const CalendarDaysPage = () => {
  const params = useParams<{ slug }>();

  return (
    <Suspense fallback={<Spin />}>
      <CalendarDaysContainer calendarSlug={params.slug} />
    </Suspense>
  );
};

export default CalendarDaysPage;
