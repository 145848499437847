import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useCallback, useMemo } from "react";
import { APP_ROUTES } from "../router/constants";

export const usePageNavigate = ({
  lastCustomCrumb,
}: {
  lastCustomCrumb?: string;
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const currentPath = location?.pathname;

  const isCalendarsNestedRoute = currentPath.includes("/calendars/");
  const isCustomersNestedRoute = currentPath.includes("/customers/");

  const navigateBack = useCallback(() => {
    if (isCalendarsNestedRoute) {
      navigate(APP_ROUTES.calendars, { state: { from: currentPath } });
    } else if (isCustomersNestedRoute && !location.state) {
      navigate(APP_ROUTES.customers);
    } else navigate(-1);
  }, [
    currentPath,
    navigate,
    location,
    isCustomersNestedRoute,
    isCalendarsNestedRoute,
  ]);

  const crumbsArr = useMemo(
    () => location.pathname.split("/").filter((item) => !!item),
    [location.pathname]
  );

  const crumbsRoutes = useMemo(
    () =>
      crumbsArr.map((crumb) => ({
        title: crumb,
        path: `/${crumb}`,
      })),
    [crumbsArr]
  );

  const renderCrumbs = useCallback(
    (currentRoute, params, items, paths) => {
      const isLast = currentRoute?.path === items[items.length - 1]?.path;

      return isLast ? (
        <span className="crumbs-label">
          {lastCustomCrumb || currentRoute.title}
        </span>
      ) : (
        <Link className="crumbs-link" to={`/${paths.join("/")}`}>
          {currentRoute.title}
        </Link>
      );
    },
    [lastCustomCrumb]
  );

  return { crumbsRoutes, renderCrumbs, navigateBack };
};
