import React, { Suspense } from "react";
import { Spin } from "antd";

const GatewaysContainer = React.lazy(
  () => import("../../../modules/Gateways/components/index")
);

const GatewaysPage = () => (
  <Suspense fallback={<Spin />}>
    <GatewaysContainer />
  </Suspense>
);

export default GatewaysPage;
