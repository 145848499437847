import React from "react";
import { Button } from "antd";
import { Field, FormikProvider, useFormik } from "formik";
import {
  AntInput,
  AntInputPassword,
} from "../../../../components/atoms/AntField";
import { LoginSchema } from "../../../../system/helpers/validation";

const LoginForm: React.FC<any> = ({ onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnBlur: false,
    validationSchema: LoginSchema,
    onSubmit: onSubmit,
  });

  const { values, submitCount, isValid, handleChange, handleBlur } = formik;

  const submitForm = (e) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={submitForm} className="login-form-container">
        <Field
          component={AntInput}
          name="email"
          type="email"
          placeholder="Email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          submitCount={submitCount}
        />
        <Field
          component={AntInputPassword}
          name="password"
          type="password"
          placeholder="Password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          submitCount={submitCount}
        />
        <Button
          type="primary"
          htmlType="submit"
          disabled={!isValid}
          size="large"
          className="login-form-submit"
        >
          Log into Hub
        </Button>
      </form>
    </FormikProvider>
  );
};

export default LoginForm;
