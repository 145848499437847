import React from "react";
import { GLOBAL_CONFIG } from "../../components/organisms/Sidebar/constants";
import { ReactComponent as GearIcon } from "../../images/icons/elements/gear.svg";
export const UPDATE_ACTIVE_BRAND = "UPDATE_ACTIVE_BRAND";

export const globalConfigState = {
  name: GLOBAL_CONFIG,
  icon: <GearIcon />,
  color: "#FFA53A",
  id: null,
};

export const activeBrandInitialState = {
  name: "",
  color: "",
  icon: null,
  id: null,
};

export type activeBrandDataType = {
  name: string;
  icon: React.ReactElement;
  color: string;
  id: string;
};

export const cachedBrand = JSON.parse(localStorage.getItem("brand"));
