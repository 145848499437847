import React from "react";
import { getBrandCode } from "../../../system/helpers/helpfulFunctions";

interface BrandIconProps {
  brandName: string;
  backgroundColor?: string;
  opacity?: string;
}

const BrandIcon: React.FC<BrandIconProps> = ({
  brandName,
  opacity,
  backgroundColor,
}) => (
  <span
    className="brand-icon"
    style={{ backgroundColor: backgroundColor, opacity: opacity }}
  >
    <span>{getBrandCode(brandName)}</span>
  </span>
);

export default BrandIcon;
