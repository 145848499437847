import React, { Suspense } from "react";
import { Spin } from "antd";
const PromotionsContainer = React.lazy(
  () => import("../../modules/Promotions/components")
);

const PromotionsPage = () => (
  <Suspense fallback={<Spin />}>
    <PromotionsContainer />
  </Suspense>
);

export default PromotionsPage;
