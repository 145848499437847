import React, { Suspense } from "react";
import { Spin } from "antd";
const QuizContainer = React.lazy(
  () => import("../../modules/Quiz/components/index")
);

const QuizPage = () => (
  <Suspense fallback={<Spin />}>
    <QuizContainer />
  </Suspense>
);

export default QuizPage;
