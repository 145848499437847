import { brandDataType } from "../Brands/constants";

export const LOGIN_URL = "auth";
export const LOGOUT_URL = "auth/:auth";
export const FORGOT_PASSWORD_URL = "password-reset-request";
export const RESET_PASSWORD_URL = "password-reset";
export const ADMIN_INFO_URL = "profile";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

export const ADMIN_INFO_START = "ADMIN_INFO_START";
export const ADMIN_INFO_SUCCESS = "ADMIN_INFO_SUCCESS";
export const ADMIN_INFO_ERROR = "ADMIN_INFO_ERROR";

export const ADMIN_PROFILE = "admin_profile";

type roleDataType = {
  id: number;
  name: string;
  guard_name: string;
  permissions: [];
};

export type authDataType = {
  id: number;
  name: string;
  email: string;
  active: boolean;
  settings: [];
  roles: roleDataType[];
  brands: brandDataType[];
  permissions: { group: string; alias: string; action: string }[];
  isLoading: boolean;
  message: string;
  success: boolean;
  socket: string;
  token: string;
  profile: {
    id: number;
    name: string;
    email: string;
    active: boolean;
    setting: [];
    roles: [
      {
        id: number;
        name: string;
        guard_name: string;
        permissions: [];
      }
    ];
    permissions: [];
    brands: brandDataType[];
  };
};
