import React, { Suspense } from "react";
import { Spin } from "antd";
const AccountContainer = React.lazy(
  () => import("../../modules/Accounts/components/index")
);

const AccountListPage = () => (
  <Suspense fallback={<Spin />}>
    <AccountContainer />
  </Suspense>
);

export default AccountListPage;
