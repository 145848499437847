import * as actionTypes from "../constants";
import { activeBrandInitialState, globalConfigState } from "../constants";
import { LOGOUT_SUCCESS } from "../../Auth/constants";

export default function activeBrand(state = globalConfigState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_ACTIVE_BRAND:
      return {
        name: action.payload.name,
        id: action.payload.id,
        icon: action.payload.icon,
        color: action.payload.color,
      };
    case LOGOUT_SUCCESS: {
      return activeBrandInitialState;
    }
    default:
      return state;
  }
}
