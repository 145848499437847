import React, { Suspense } from "react";
import { Spin } from "antd";
const ImagesPackContainer = React.lazy(
  () => import("../../../modules/ImagesPack/components/index")
);

const ImagesPackPage = () => (
  <Suspense fallback={<Spin />}>
    <ImagesPackContainer />
  </Suspense>
);

export default ImagesPackPage;
