import { message } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../router/constants";

export const useApiRequest = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const apiRequest = (params, withHeaders = false) =>
    dispatch(params).then((data: any) => {
      if (!!params.meta) {
        return {
          data: data.payload,
          meta: params.meta,
        };
      }

      if (!withHeaders) {
        return data.payload;
      }

      return {
        data: data.payload,
        headers: data.resHeaders,
      };
    });

  const apiErrors = (err, notify = true) => {
    const errors = err.error?.data?.errors;
    const errMessage = err.error?.data?.message;

    if (err.error && err.error.status === 401) {
      navigate(APP_ROUTES.login);
    }

    if (notify) {
      if (!!errors) {
        Object.keys(errors).forEach((error) => message.error(errors[error][0]));
      } else if (!!errMessage) {
        message.error(errMessage);
      } else {
        message.error("Some error occurred");
      }
    }

    return errors;
  };

  return { apiRequest, apiErrors };
};
