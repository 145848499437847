import React from "react";
import hubLogo from "../../../../images/icons/logo/hub-logo.svg";
import classNames from "classnames";
import { Link } from "react-router-dom";
import hubLogoLoader from "../../../../images/icons/logo/hub-logo-loading.gif";

interface AuthWrapperProps {
  children: React.ReactElement;
  isLoading: boolean;
  pageContent: string;
  pageSubContent?: string;
  actionLink?: string;
  actionText?: string;
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({
  children,
  isLoading,
  pageContent,
  pageSubContent,
  actionLink,
  actionText,
}) => {
  return (
    <div className="auth-container">
      <div className="logo-section">
        <img
          src={hubLogo}
          alt="hub-logo"
          className={classNames("hub-logo", { hidden: isLoading })}
        />
        <img
          src={hubLogoLoader}
          className={classNames("hub-logo-loader", { hidden: !isLoading })}
          alt="loader"
        />
        <span className="logo-section__hub">HUB</span>
      </div>
      {!!pageContent && (
        <span className="greeting-message-block">{pageContent}</span>
      )}
      {!!pageSubContent && (
        <span className="sub-content-block">{pageSubContent}</span>
      )}
      {children}
      {!!actionLink && (
        <Link className="auth-wrapper-action" to={actionLink}>
          {actionText}
        </Link>
      )}
    </div>
  );
};

AuthWrapper.defaultProps = {
  pageSubContent: "",
};

export default AuthWrapper;
