export const BOOTSTRAP_INFO_URL = "bootstrap";

export const BOOTSTRAP_INFO = "bootstrap_info";

export const GET_BOOTSTRAP_INFO_SUCCESS = "GET_BOOTSTRAP_INFO_SUCCESS";

export type bootstrapDataType = {
  cdn: string;
  constants: {
    languages: {
      [key: string]: string;
    };
    currencies: {
      [key: string]: string;
    };
    platforms: {
      [key: string]: string;
    };
    countries: {
      [key: string]: string;
    };
  };
};

export type bootstrapActionType = {
  type: string;
  payload: bootstrapDataType;
};
