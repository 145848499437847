import { useQuery } from "@tanstack/react-query";
import { BOOTSTRAP_INFO, BOOTSTRAP_INFO_URL } from "../constants";
import { useApiRequest } from "../../../system/hooks/useApiRequest";

export const useGetBootstrapInfo = () => {
  const { apiRequest } = useApiRequest();
  return useQuery({
    queryKey: [BOOTSTRAP_INFO],
    queryFn: () =>
      apiRequest({
        type: "GET_BOOTSTRAP_INFO",
        request: {
          url: BOOTSTRAP_INFO_URL,
          method: "GET",
        },
      }),
  });
};
