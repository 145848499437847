import { useMutation, useQuery } from "@tanstack/react-query";
import { removeCookie, setCookie } from "../../../system/helpers/cookies";
import { message } from "antd";
import { APP_ROUTES } from "../../../system/router/constants";
import {
  ADMIN_INFO_URL,
  ADMIN_PROFILE,
  authDataType,
  FORGOT_PASSWORD_URL,
  LOGIN_URL,
  LOGOUT_URL,
  RESET_PASSWORD_URL,
} from "../constants";

import { useApiRequest } from "../../../system/hooks/useApiRequest";
import { generatePath, useNavigate } from "react-router-dom";
import {
  getBrandCode,
  isLoggedIn,
} from "../../../system/helpers/helpfulFunctions";
import { useDispatch } from "react-redux";
import updateActiveBrand from "../../ActiveBrand/api/action";
import { Role } from "../../../system/roles/constants";
import { globalConfigState } from "../../ActiveBrand/constants";
import { ErrorType } from "../../../system/types/constants";

export const useLogin = () => {
  const { apiRequest } = useApiRequest();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (data: any): Promise<authDataType> =>
      apiRequest({
        type: "LOGIN",
        request: {
          url: LOGIN_URL,
          method: "POST",
          data: data,
        },
      }),
    onSuccess: (data) => {
      setCookie("token", data.token);
      navigate(APP_ROUTES.dashboard);

      const isSuperAdmin = Role.SuperAdmin === data.profile.name;
      const hasGlobalConfigAccess = !!data.profile.permissions.filter(
        (perm: any) => perm.alias.toLowerCase().includes("system")
      ).length;

      if (!(isSuperAdmin || hasGlobalConfigAccess)) {
        const brand = data.profile.brands[0];
        dispatch(
          updateActiveBrand({
            name: brand.name,
            icon: getBrandCode(brand.name),
            color: brand.settings["color"],
            id: brand.id,
          })
        );
      } else {
        dispatch(updateActiveBrand(globalConfigState));
      }
      localStorage.setItem(
        "authData",
        JSON.stringify({
          name: data.profile.name,
          permissions: data.profile.permissions,
        })
      );
    },
    onError: (err: any) => {
      console.log(err);
      message.error(err.error ? err.error.data.message : "Login error message");
    },
  });
};

export const useLogout = () => {
  const { apiRequest } = useApiRequest();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (token: string) =>
      apiRequest({
        type: "LOGOUT",
        request: {
          url: generatePath(LOGOUT_URL, { auth: token }),
          method: "DELETE",
        },
      }),
    onSuccess: () => {
      removeCookie("token");
      localStorage.removeItem("brand");
      localStorage.removeItem("authData");
      navigate(APP_ROUTES.login);
    },
    onError: (err: any) => {
      console.log(err);
      message.error(
        err.error ? err.error.data.message : "Logout error message"
      );
    },
  });
};

export const useForgotPassword = () => {
  const { apiRequest } = useApiRequest();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (data: any) =>
      apiRequest({
        type: "FORGOT_PASSWORD",
        request: {
          url: FORGOT_PASSWORD_URL,
          method: "POST",
          data: data,
        },
      }),
    onSuccess: (data: any) => {
      navigate(APP_ROUTES.login);
      message.success(
        !!data.message
          ? data.message
          : "You will receive an email with instructions about how to\n reset your password in a few minutes."
      );
    },
    onError: (err: any) => {
      console.log(err);
      message.error(
        err.error ? err.error.data.message : "Forgot password error message"
      );
    },
  });
};

export const useResetPassword = () => {
  const { apiRequest } = useApiRequest();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (data: any): Promise<authDataType> =>
      apiRequest({
        type: "RESET_PASSWORD",
        request: {
          url: RESET_PASSWORD_URL,
          method: "POST",
          data: data,
        },
      }),
    onSuccess: (data) => {
      navigate(APP_ROUTES.login);
      message.success(
        !!data.message
          ? data.message
          : "Password has been reset successfully. \n" +
              "Use the new password to login."
      );
    },
    onError: (err: any) => {
      console.log(err);
      message.error(
        err.error ? err.error.data.message : "Reset password error message"
      );
    },
  });
};

export const useAdminProfile = () => {
  const { apiRequest, apiErrors } = useApiRequest();

  return useQuery({
    queryKey: [ADMIN_PROFILE],
    queryFn: () =>
      apiRequest({
        type: "ADMIN_INFO",
        request: {
          url: ADMIN_INFO_URL,
          method: "GET",
        },
      }),
    enabled: isLoggedIn(),
    throwOnError: (err: ErrorType) => apiErrors(err),
  });
};
