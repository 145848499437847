import React, { Suspense } from "react";
import { Spin } from "antd";
const AccountTemplate = React.lazy(
  () => import("../../../../modules/Accounts/components/AccountTemplate")
);

const AccountEditPage = () => (
  <Suspense fallback={<Spin />}>
    <AccountTemplate type="edit" />
  </Suspense>
);

export default AccountEditPage;
