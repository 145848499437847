import React from "react";
import { DatePicker, Form, Input, TimePicker, Select, InputNumber } from "antd";

const AntField = (AntComponent) => ({
  field,
  form,
  label,
  tooltip,
  selectOptions,
  submitCount,
  ...props
}) => {
  const touched = form.touched[field.name];
  const submitted = submitCount > 0;
  const hasError = form.errors[field.name];
  const submittedError = hasError && submitted;
  const touchedError = hasError && touched;
  const onBlur = () => form.setFieldTouched(field.name, true);
  return (
    <Form.Item
      label={label}
      tooltip={tooltip}
      labelAlign="left"
      help={submittedError || touchedError ? hasError : false}
      validateStatus={submittedError || touchedError ? "error" : "success"}
    >
      <AntComponent {...field} {...props} onBlur={onBlur}>
        {selectOptions &&
          selectOptions.map((name) => (
            <Select.Option key={name} value={name}>
              {name}
            </Select.Option>
          ))}
      </AntComponent>
    </Form.Item>
  );
};

export const AntInput = AntField(Input);
export const AntInputPassword = AntField(Input.Password);
export const AntSelect = AntField(Select);
export const AntInputNumber = AntField(InputNumber);
export const AntDatePicker = AntField(DatePicker);
export const AntTimePicker = AntField(TimePicker);
export const AntTextarea = AntField(Input.TextArea);
