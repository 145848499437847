import React, { useState } from "react";
import Paragraph from "antd/es/typography/Paragraph";
import { Tooltip } from "antd";

interface TooltipParagraphProps {
  ellipsis?: {};
  children: React.ReactNode;
  placement?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom";
}

const TooltipParagraph: React.FC<TooltipParagraphProps> = ({
  children,
  ellipsis,
  placement,
  ...props
}) => {
  const [truncated, setTruncated] = useState(false);

  return (
    <Tooltip
      mouseLeaveDelay={0}
      placement={placement}
      title={truncated ? children : undefined}
      destroyTooltipOnHide={false}
    >
      <Paragraph
        {...props}
        ellipsis={{ ...ellipsis, onEllipsis: setTruncated }}
      >
        <>{children}</>
      </Paragraph>
    </Tooltip>
  );
};

TooltipParagraph.defaultProps = {
  placement: "bottom",
};

export default TooltipParagraph;
