import React, { Suspense } from "react";
import { Spin } from "antd";
const CalendarsContainer = React.lazy(
  () => import("../../modules/Calendars/components")
);

const CalendarsPage = () => (
  <Suspense fallback={<Spin />}>
    <CalendarsContainer />
  </Suspense>
);

export default CalendarsPage;
