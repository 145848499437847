import * as actionTypes from "../constants";
import { bootstrapDataType, bootstrapActionType } from "../constants";

const initialState: bootstrapDataType = {
  cdn: "",
  constants: {
    languages: {},
    currencies: {},
    platforms: {},
    countries: {},
  },
};

const bootstrapReducer = (
  state: bootstrapDataType = initialState,
  action: bootstrapActionType
): bootstrapDataType => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.GET_BOOTSTRAP_INFO_SUCCESS:
      return {
        cdn: payload.cdn,
        constants: {
          languages: payload.constants.languages,
          currencies: payload.constants.currencies,
          platforms: payload.constants.platforms,
          countries: payload.constants.countries,
        },
      };
    default:
      return state;
  }
};

export default bootstrapReducer;
