import React from "react";
import { message } from "antd";
import { Provider } from "react-redux";
import { store } from "./system/store";
import { BrowserRouter } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ModalContainer from "./components/organisms/Modal";
import SystemTemplate from "./templates/System";
import MainTemplate from "./templates/Main";
import routes from "./system/router/routes";

import "./styles/main.scss";
import "antd/dist/reset.css";

message.config({
  duration: 3,
  maxCount: 3,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: 10 * 60 * 1000,
    },
    mutations: {
      // mutation options
    },
  },
});

const App: React.FC = () => (
  <Provider store={store}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <SystemTemplate>
          <MainTemplate>{routes}</MainTemplate>
        </SystemTemplate>
        <ModalContainer />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  </Provider>
);

export default App;
