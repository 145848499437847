import React, { Suspense } from "react";
import { Spin } from "antd";
const NewsContainer = React.lazy(
  () => import("../../../modules/News/components/index")
);

const NewsPage = () => (
  <Suspense fallback={<Spin />}>
    <NewsContainer />
  </Suspense>
);

export default NewsPage;
