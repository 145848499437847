import React from "react";
import { useResetPassword } from "../../../modules/Auth/api/apiHooks";
import AuthWrapper from "../../../modules/Auth/components/wrappers/AuthWrapper";
import { APP_ROUTES } from "../../../system/router/constants";
import ResetPasswordForm from "../../../modules/Auth/components/forms/ResetPasswordForm";
import { useLocation } from "react-router-dom";
import { getQueryParams } from "../../../system/helpers/urlHelper";

const ResetPassword = () => {
  const { mutate, isPending } = useResetPassword();

  const location = useLocation();
  const params = getQueryParams(location.search);

  const onSubmit = (values) =>
    mutate({
      email: values.email,
      password: values.password,
      password_confirmation: values.repeatPassword,
      token: params.get("token"),
    });

  return (
    <AuthWrapper
      isLoading={isPending}
      pageContent="Reset Password"
      pageSubContent="Enter new password for your account"
      actionLink={APP_ROUTES.login}
      actionText="Return to login"
    >
      <ResetPasswordForm onSubmit={onSubmit} />
    </AuthWrapper>
  );
};

export default ResetPassword;
