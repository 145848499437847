const projectEnv: string = process.env.REACT_APP_ENV || "";
const developmentEnv = process.env.REACT_APP_DEVELOPMENT_ENV || ""; //local

const developmentUrl =
  developmentEnv === "local"
    ? "http://localhost/api"
    : "http://localhost:3000/api";

let backendUrl: string = "";
const host = window.location.hostname;
const protocol = window.location.protocol;
const stageEnv = `${protocol}//${host}/api`; // url for stg env

switch (projectEnv) {
  case "development":
    backendUrl = developmentUrl || `${protocol}//${host}/api`;
    break;
  case "staging":
    backendUrl = stageEnv;
    // backendUrl = `${protocol}//${host}`;
    break;
  default:
    backendUrl = `${protocol}//${host}/api`;
    break;
}

export default backendUrl;
