import React, { Suspense } from "react";
import { Spin } from "antd";
const RoleContainer = React.lazy(
  () => import("../../modules/Roles/components/index")
);

const RolesPage = () => (
  <Suspense fallback={<Spin />}>
    <RoleContainer />
  </Suspense>
);

export default RolesPage;
