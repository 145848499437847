import { GLOBAL_CONFIG } from "../../../components/organisms/Sidebar/constants";
import { UPDATE_ACTIVE_BRAND } from "../constants";

const updateActiveBrand = (brand) => {
  if (brand.name !== GLOBAL_CONFIG) {
    localStorage.setItem(
      "brand",
      JSON.stringify({
        name: brand.name,
        icon: brand.icon,
        id: brand.id,
        color: brand.color,
      })
    );
  } else {
    localStorage.removeItem("brand");
  }
  return {
    type: UPDATE_ACTIVE_BRAND,
    payload: brand,
  };
};

export default updateActiveBrand;
