import React, { Suspense } from "react";
import { Spin } from "antd";
const DepositContainer = React.lazy(
  () => import("../../../modules/Deposit/components")
);

const DepositPage = () => (
  <Suspense fallback={<Spin />}>
    <DepositContainer />
  </Suspense>
);

export default DepositPage;
